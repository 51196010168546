import React, { Component } from "react";
import { Modal, Button, Icon, Form, Input, Dropdown } from "semantic-ui-react";
import { usersOperations } from "../../../../redux/users";
import _ from "lodash";

export default class CreateUserModal extends Component {
  state = {
    open: false,
    options: [],
  };

  constructor(props) {
    super(props);

    const optionPossibilities = [
      { value: "SRH_ADMIN", role: "SRH_ADMIN" },
      { value: "SYSTEM_ADMIN" },
      { value: "SURVEY_MANAGER_USER" },
      { value: "DEBRIEF_USER" },
      { value: "OBSERVATION_REPORT_USER" },
    ];

    for (let i = 0; i < optionPossibilities.length; i++) {
      const option = optionPossibilities[i];
      if (!option.role || _.includes(this.props.authenticatedUser.roles, option.role)) {
        this.state.options.push({
          key: option.value,
          value: option.value,
          text: option.value,
        });
      }
    }
  }

  open = () =>
    this.setState({
      open: true,
      username: "",
      password: "",
      roles: [],
      system: this.props.authenticatedUser.system,
    });
  close = () => this.setState({ open: false });

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = () => {
    const { username, password, roles, system } = this.state;
    this.props.dispatch(usersOperations.createUser({ username, password, roles, system }));
    this.close();
  };

  render() {
    return (
      <Modal
        trigger={
          <Button>
            {" "}
            <Icon name="add" /> Create User
          </Button>
        }
        open={this.state.open}
        onOpen={this.open}
        onClose={this.close}
      >
        <Modal.Header>Create User</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form onSubmit={this.handleSubmit}>
              <Form.Field
                control={Input}
                label="Username/Email"
                placeholder="Username/Email"
                name="username"
                value={this.state.username}
                onChange={this.handleChange}
                required
              />
              <Form.Field
                control={Input}
                label="Password"
                placeholder="Password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
                required
              />
              <Form.Field
                control={Dropdown}
                label="Roles"
                placeholder="Roles"
                name="roles"
                value={this.state.roles}
                onChange={this.handleChange}
                options={this.state.options}
                multiple
                search
                selection
              />
              {_.includes(this.props.authenticatedUser.roles, "SRH_ADMIN") &&
                !_.includes(this.state.roles, "SRH_ADMIN") && (
                  <Form.Field
                    control={Input}
                    label="System"
                    placeholder="System"
                    name="system"
                    value={this.state.system}
                    onChange={this.handleChange}
                    required
                  />
                )}
              <Form.Field control={Button}>Create</Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}
