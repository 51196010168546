import { applyMiddleware, createStore, compose } from "redux";

import thunk from "redux-thunk";
import logger from "redux-logger";
import reducers from "./reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = applyMiddleware(thunk, logger);

export default createStore(reducers, composeEnhancers(middleware));
