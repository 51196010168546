import React from "react";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { SendAccountSetupInstructionsPage, Modal, ModalMessage, Logo } from "./style.js";
import { userOperations } from "../../redux/user";
import qs from "query-string";
import logo from "../../assets/img/logo/fulllogo.svg";

class SendAccountSetupInstructions extends React.Component {
  constructor(props) {
    super(props);
    const location = window.location;
    const email = qs.parse(location?.search)?.email;
    const referrer = qs.parse(location?.search)?.referrer;
    if (!email) {
      console.error("There is no email in the url");
    }
    this.state = { email };
    this.props.dispatch(userOperations.sendAccountSetupInstructions(email, referrer));
  }

  render() {
    return (
      <SendAccountSetupInstructionsPage>
        <Modal>
          <Logo>
            <img src={logo} alt="Safe and Reliable Care" />
          </Logo>
          {!this.props.accountSetupInstructionsSent &&
            !this.props.accountSetupInstructionsError && (
              <div style={{ height: "77px" }}>
                <Loader active style={{ marginTop: "100px" }}>
                  Please wait
                </Loader>
              </div>
            )}
          {this.props.accountSetupInstructionsSent && (
            <ModalMessage>
              <h4>Check your inbox!</h4>
              <div>
                Looks like you need to set up your account before proceeding. Account setup instructions have just been
                sent to {this.state.email}.
              </div>
            </ModalMessage>
          )}
          {this.props.accountSetupInstructionsError && (
            <ModalMessage>
              <h4>Error</h4>
              Try reloading the page or feel free to email us at <strong>surveysupport@safeandreliablecare.com </strong>
              if the issue persists.
            </ModalMessage>
          )}
        </Modal>
      </SendAccountSetupInstructionsPage>
    );
  }
}

export default connect(store => {
  return {
    accountSetupInstructionsSent: store.user.accountSetupInstructionsSent,
    accountSetupInstructionsError: store.user.accountSetupInstructionsError,
  };
})(SendAccountSetupInstructions);
