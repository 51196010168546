const theme = {
  primary:        '#05435e',
  primaryLight:   '#138BB5',
  primaryXLight:  '#9FD6DC',
  primaryXXLight: '#b3eff5',
  primaryDark:    '#005c7a',
  secondary:      '#F29F5C',
  secondaryDark:  '#d68c54',
  light:          '#F7F7F7',
  white:          '#FFFFFF',
  greyLight:      '#D8D8D8',
  grey:           '#545454',
  black:          '#000000',
  negative:       '#FF886D',
  positive:       '#5DD392',
};

export { theme };
