import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { Header, Logo, Title } from "./style.js";
import logo from "../../assets/img/logo/notextlogo.png";
import PropTypes from "prop-types";
import { userOperations } from "../../redux/user";

/**
 * Displays the SRH Logo and provided title in a blue background bar
 */
export default class SRHHeader extends Component {
  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
  }

  signOut() {
    this.props.dispatch(userOperations.signOut());
  }

  render() {
    return (
      <Header>
        <Logo className="logo">
          <img src={logo} alt="" />
        </Logo>
        <Title>
          <div> {this.props.title} </div>
        </Title>
        <Button primary onClick={this.signOut}>
          Sign out
        </Button>
      </Header>
    );
  }
}

SRHHeader.propTypes = {
  /** Title of the page */
  title: PropTypes.string,
};

SRHHeader.defaultProps = {
  title: "",
};
