import types from "./types";

const defaultValues = {
  data: [],
  fetched: false,
  error: null,
  createUserError: null,
  updateUserError: null,
  deleteUserError: null,
};

export default function reducer(state = defaultValues, action) {
  switch (action.type) {
  case types.FETCH_USERS: {
    return {
      ...state,
      data: action.payload.users,
      fetched: true,
    };
  }

  case types.FETCH_USERS_ERROR: {
    return {
      ...state,
      error: action.payload.response,
    };
  }

  case types.CREATE_USER_ERROR: {
    return {
      ...state,
      createUserError: action.payload.response,
    };
  }

  case types.UPDATE_USER_ERROR: {
    return {
      ...state,
      updateUserError: action.payload.response,
    };
  }

  case types.DELETE_USER_ERROR: {
    return {
      ...state,
      deleteUserError: action.payload.response,
    };
  }

  default: {
    return state;
  }
  }
}
