import React, { Component } from "react";
import { BrowserRouter, Switch, Routes, Route, Redirect, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { userOperations } from "../redux/user";
import SignIn from "./../pages/SignIn";
import SendAccountSetupInstructions from "../pages/SendAccountSetupInstructions";
import SendPasswordReset from "../pages/SendPasswordReset";
import ResetPassword from "../pages/ResetPassword";
import Users from "../pages/Users";
class MainRouter extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userOperations.validateSession());
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="sign-in"
            element={
              this.props.sessionChecked
                ? this.props.isLoggedIn
                  ? <Navigate to="/" replace={true} />
                  : React.createElement(SignIn, { ...this.props })
                : <div />
            }
          />
          <Route exact path="send-account-setup-instructions" element={<SendAccountSetupInstructions />} />
          <Route exact path="send-password-reset" element={<SendPasswordReset />} />
          <Route exact path="reset-password" element={ <ResetPassword /> } />
          <Route path="/"
            {...this.props}
            element={
              this.props.sessionChecked
                ? this.props.isLoggedIn
                  ? React.createElement(Users, { ...this.props })
                  : <Navigate to="sign-in" replace={true} />
                : <div />
            }
          />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default connect(store => {
  return {
    isLoggedIn: store.user.isLoggedIn,
    sessionChecked: store.user.sessionChecked,
  };
})(MainRouter);
