import styled from "styled-components";

export const SignInPage = styled.div`
  &&& {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    form {
      display: flex;
      flex-direction: column;
    }

    input,
    button {
      width: 300px;
      margin-bottom: 15px;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 3px;
      padding: 10px 14px;
      line-height: 17px;
      font-size: 14px;
      outline: none;

      &:focus {
        border-color: #85b7d9;
        box-shadow: none;
      }
    }

    .forgot-password {
      color: ${props => props.theme.primary};
      font-size: 14px;

      &:hover,
      &:focus {
        color: ${props => props.theme.primaryDark};
      }
    }

    button {
      background-color: ${props => props.theme.primary};
      color: ${props => props.theme.white};
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: ${props => props.theme.primaryDark};
      }
    }
  }
`;

export const Logo = styled.div`
  &&& {
    width: 298px;
    height: 119px;
    flex-grow: 0;
    margin: 0 19px 60px 3px;
    object-fit: contain;
  }
`;
