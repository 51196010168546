import types from "./types";

const defaultValues = {
  sessionChecked: false,
  isLoggedIn: false,
  lastLoginFailed: false,
  user: undefined,
  accountSetupInstructionsSent: false,
  accountSetupInstructionsError: false,
  sendPasswordResetPageLoad: false,
  passwordResetSent: false,
  passwordResetError: false,
  newAccount: false,
  tokenValidated: false,
  tokenValidationError: false,
  resetPassword: false,
  resetPasswordError: false,
  resetPasswordReferrer: null,
  readyToRedirect: false,
};

export default function reducer(state = defaultValues, action) {
  switch (action.type) {
  case types.USER_SIGN_IN_START: {
    return {
      ...state,
      lastLoginFailed: false,
    };
  }

  case types.USER_SIGN_IN_ERROR: {
    return {
      ...state,
      isLoggedIn: false,
      lastLoginFailed: true,
      user: undefined,
    };
  }

  case types.USER_SIGN_OUT: {
    return {
      ...state,
      isLoggedIn: false,
      user: undefined,
    };
  }

  case types.USER_VALIDATE_SESSION: {
    return {
      ...state,
      sessionChecked: true,
      lastLoginFailed: false,
      isLoggedIn: action.payload.sessionValid,
      user: action.payload.user,
    };
  }

  case types.SEND_ACCOUNT_SETUP_INSTRUCTIONS: {
    return {
      ...state,
      accountSetupInstructionsSent: true,
      accountSetupInstructionsError: false,
    };
  }

  case types.SEND_ACCOUNT_SETUP_INSTRUCTIONS_ERROR: {
    return {
      ...state,
      accountSetupInstructionsSent: false,
      accountSetupInstructionsError: true,
    };
  }

  case types.SEND_PASSWORD_RESET_PAGE_LOAD: {
    return {
      ...state,
      passwordResetSent: false,
      passwordResetError: false,
    };
  }

  case types.SEND_PASSWORD_RESET: {
    return {
      ...state,
      passwordResetSent: true,
      passwordResetError: false,
    };
  }

  case types.SEND_PASSWORD_RESET_ERROR: {
    return {
      ...state,
      passwordResetSent: false,
      passwordResetError: true,
    };
  }

  case types.VALIDATE_TOKEN: {
    return {
      ...state,
      newAccount: action.payload.newAccount,
      tokenValidated: action.payload.tokenValid,
      tokenValidationError: !action.payload.tokenValid,
    };
  }

  case types.VALIDATE_TOKEN_ERROR: {
    return {
      ...state,
      newAccount: false,
      tokenValidated: false,
      tokenValidationError: true,
    };
  }

  case types.PASSWORD_RESET: {
    return {
      ...state,
      resetPassword: true,
      resetPasswordError: false,
      resetPasswordReferrer: action.payload.referrer,
    };
  }

  case types.PASSWORD_RESET_ERROR: {
    return {
      ...state,
      resetPassword: false,
      resetPasswordError: true,
    };
  }

  case types.READY_TO_REDIRECT: {
    return {
      ...state,
      readyToRedirect: true,
    };
  }

  default: {
    return state;
  }
  }
}
