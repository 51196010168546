import axios from "axios";
import actions from "./actions";

const signIn = (username, password) => (dispatch, getState) => {
  dispatch(actions.signInStart());
  axios
    .post("/api/sign-in", { username, password })
    .then(res => {
      axios.get("/api/session-status").then(res => {
        dispatch(actions.validateSession(res.data));
      });
    })
    .catch(err => {
      dispatch(actions.signInError(err));
    });
};

const signOut = () => (dispatch, getState) => {
  axios.get("/api/sign-out").then(res => {
    dispatch(actions.signOut(res.data));
  });
};

const validateSession = () => (dispatch, getState) => {
  axios.get("/api/session-status").then(res => {
    dispatch(actions.validateSession(res.data));
  });
};

const sendAccountSetupInstructions = (email, referrer) => (dispatch, getState) => {
  if (!email) {
    dispatch(actions.sendAccountSetupInstructionsError());
  } else {
    axios
      .post(`/api/users/send-account-setup-instructions`, { email, referrer })
      .then(res => {
        dispatch(actions.sendAccountSetupInstructions(res.data));
      })
      .catch(err => {
        dispatch(actions.sendAccountSetupInstructionsError(err));
      });
  }
};

const sendPasswordReset = (email, referrer) => (dispatch, getState) => {
  if (!email) {
    dispatch(actions.sendPasswordResetError());
  } else {
    axios
      .post(`/api/users/send-password-reset`, { email, referrer })
      .then(res => {
        dispatch(actions.sendPasswordReset(res.data));
      })
      .catch(err => {
        dispatch(actions.sendPasswordResetError(err));
      });
  }
};

const validateToken = token => (dispatch, getState) => {
  if (!token) {
    dispatch(actions.validateTokenError());
  } else {
    axios
      .get(`/api/password-reset-tokens/${token}`)
      .then(res => {
        dispatch(actions.validateToken(res.data));
      })
      .catch(err => {
        dispatch(actions.validateTokenError(err));
      });
  }
};

const resetPassword = (password, token) => (dispatch, getState) => {
  axios
    .put(`/api/reset-password`, { password, token })
    .then(resetPasswordResponse => {
      dispatch(actions.resetPassword(resetPasswordResponse.data));
      axios
        .post("/api/sign-in", { username: resetPasswordResponse.data.username, password })
        .then(signInResponse => {
          axios.get("/api/session-status").then(sessionStatusResponse => {
            dispatch(actions.validateSession(sessionStatusResponse.data));
            if (resetPasswordResponse.data.referrer) {
              dispatch(actions.readyToRedirect());
            }
          });
        })
        .catch(err => {
          dispatch(actions.signInError(err));
        });
    })
    .catch(err => {
      dispatch(actions.resetPasswordError(err));
    });
};

const sendPasswordResetPageLoad = () => (dispatch, getState) => {
  dispatch(actions.sendPasswordResetPageLoad());
};

export default {
  signIn,
  signOut,
  validateSession,
  sendAccountSetupInstructions,
  sendPasswordResetPageLoad,
  sendPasswordReset,
  validateToken,
  resetPassword,
};
