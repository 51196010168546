export const FETCH_USERS = "app/users/FETCH_USERS";
export const FETCH_USERS_ERROR = "app/users/FETCH_USERS_ERROR";
export const CREATE_USER_ERROR = "app/users/CREATE_USER_ERROR";
export const UPDATE_USER_ERROR = "app/users/UPDATE_USER_ERROR";
export const DELETE_USER_ERROR = "app/users/DELETE_USER_ERROR";
export const SEND_PASSWORD_RESET = "app/users/SEND_PASSWORD_RESET";
export const SEND_PASSWORD_RESET_ERROR = "app/users/SEND_PASSWORD_RESET_ERROR";

export default {
  FETCH_USERS,
  FETCH_USERS_ERROR,
  CREATE_USER_ERROR,
  UPDATE_USER_ERROR,
  DELETE_USER_ERROR,
};
