import types from "./types";

const signInStart = () => {
  return {
    type: types.USER_SIGN_IN_START,
  };
};

const signInError = err => {
  return {
    type: types.USER_SIGN_IN_ERROR,
    payload: err,
  };
};

const signOut = response => {
  return {
    type: types.USER_SIGN_OUT,
    payload: response,
  };
};

const validateSession = sessionValid => {
  return {
    type: types.USER_VALIDATE_SESSION,
    payload: sessionValid,
  };
};

const sendAccountSetupInstructions = response => {
  return {
    type: types.SEND_ACCOUNT_SETUP_INSTRUCTIONS,
    payload: response,
  };
};

const sendAccountSetupInstructionsError = err => {
  return {
    type: types.SEND_ACCOUNT_SETUP_INSTRUCTIONS_ERROR,
    payload: err,
  };
};

const sendPasswordResetPageLoad = response => {
  return {
    type: types.SEND_PASSWORD_RESET_PAGE_LOAD,
    payload: {},
  };
};

const sendPasswordReset = response => {
  return {
    type: types.SEND_PASSWORD_RESET,
    payload: response,
  };
};

const sendPasswordResetError = err => {
  return {
    type: types.SEND_PASSWORD_RESET_ERROR,
    payload: err,
  };
};

const validateToken = response => {
  return {
    type: types.VALIDATE_TOKEN,
    payload: response,
  };
};

const validateTokenError = err => {
  return {
    type: types.VALIDATE_TOKEN_ERROR,
    payload: err,
  };
};

const resetPassword = response => {
  return {
    type: types.PASSWORD_RESET,
    payload: response,
  };
};

const resetPasswordError = err => {
  return {
    type: types.PASSWORD_RESET_ERROR,
    payload: err,
  };
};

const readyToRedirect = () => {
  return {
    type: types.READY_TO_REDIRECT,
    payload: {},
  };
};

export default {
  signInStart,
  signInError,
  signOut,
  validateSession,
  sendAccountSetupInstructions,
  sendAccountSetupInstructionsError,
  sendPasswordResetPageLoad,
  sendPasswordReset,
  sendPasswordResetError,
  validateToken,
  validateTokenError,
  resetPassword,
  resetPasswordError,
  readyToRedirect,
};
