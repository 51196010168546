import styled from "styled-components";

export const Header = styled.div`
  &&& {
    background: ${props => props.theme.primary};
    height: 50px;
    padding: 0 10px;
    display: flex;
  }
`;

export const Logo = styled.div`
  &&& {
    display: block;
    float: left;
    padding: 6px 0 0 10px;
    cursor: default;

    img {
      width: 30px;
    }
  }
`;

export const Title = styled.div`
  &&& {
    display: flex;
    flex: 1;
    cursor: default;

    div {
      color: white;
      font-size: 18px;
      font-weight: 500;
      padding-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 759px) {
        font-size: 14px;
      }
    }
  }
`;
