import React, { Component } from "react";
import { Modal, Button, Form, Input, Dropdown } from "semantic-ui-react";
import { usersOperations } from "../../../../redux/users";
import _ from "lodash";

export default class UpdateUserModal extends Component {
  state = {
    id: "",
    username: "",
    password: "",
    roles: [],
    system: "",
    open: false,
    options: [],
  };

  constructor(props) {
    super(props);

    const optionPossibilities = [
      { value: "SRH_ADMIN", role: "SRH_ADMIN" },
      { value: "SYSTEM_ADMIN" },
      { value: "SURVEY_MANAGER_USER" },
      { value: "DEBRIEF_USER" },
      { value: "OBSERVATION_REPORT_USER" },
    ];

    for (let i = 0; i < optionPossibilities.length; i++) {
      const option = optionPossibilities[i];
      if (!option.role || _.includes(this.props.authenticatedUser.roles, option.role)) {
        this.state.options.push({
          key: option.value,
          value: option.value,
          text: option.value,
        });
      }
    }
  }

  open = () =>
    this.setState({
      id: this.props.user.id,
      username: this.props.user.username,
      roles: this.props.user.roles,
      system: this.props.user.system,
      open: true,
    });

  close = () => this.setState({ open: false });

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = () => {
    const { id, username, password, roles, system } = this.state;
    this.props.dispatch(
      usersOperations.updateUser({ id, username, password, roles, system: roles.includes("SRH_ADMIN") ? "" : system })
    );
    this.close();
  };

  handleDelete = () => {
    const { id } = this.state;
    this.props.dispatch(usersOperations.deleteUser(id));
    this.close();
  };

  render() {
    return (
      <Modal trigger={this.props.trigger} open={this.state.open} onOpen={this.open} onClose={this.close}>
        <Modal.Header>Update User</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form onSubmit={this.handleSubmit}>
              <Form.Field
                control={Input}
                label="Username/Email"
                placeholder="Username/Email"
                name="username"
                value={this.state.username}
                onChange={this.handleChange}
                required
              />
              <Form.Field
                control={Input}
                label="Password"
                placeholder="Password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
              />
              <Form.Field
                control={Dropdown}
                label="Roles"
                placeholder="Roles"
                name="roles"
                value={this.state.roles}
                onChange={this.handleChange}
                options={this.state.options}
                multiple
                search
                selection
              />
              {_.includes(this.props.authenticatedUser.roles, "SRH_ADMIN") &&
                !_.includes(this.state.roles, "SRH_ADMIN") && (
                  <Form.Field
                    control={Input}
                    label="System"
                    placeholder="System"
                    name="system"
                    value={this.state.system}
                    onChange={this.handleChange}
                    required
                  />
                )}
              <Form.Group>
                <Form.Field control={Button}>Update</Form.Field>
                <Button color="red" onClick={this.handleDelete}>
                  Delete
                </Button>
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}
