import React, { Component } from "react";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { theme } from "./theme.js";
import store from "./../config/store.js";
import MainRouter from "./MainRouter";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <MainRouter />
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;
