import React from "react";
import { StyledTable, TableTitle, Title, ActionBar, TableBody } from "./style.js";
import PropTypes from "prop-types";

export default class Table extends React.Component {
  render() {
    return (
      <StyledTable>
        <TableTitle>
          <Title>{this.props.title}</Title>
          <ActionBar>{this.props.buttons}</ActionBar>
        </TableTitle>
        <TableBody>
          <table className={this.props.paddedRows ? "" : "skinny-rows"}>
            <thead>
              <tr>{this.props.columns.map((column, index) => <th key={`${column}-${index}`}>{column}</th>)}</tr>
            </thead>
            <tbody>{this.props.children}</tbody>
          </table>
        </TableBody>
      </StyledTable>
    );
  }
}
Table.propTypes = {
  paddedRows: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  children: PropTypes.any,
};
Table.defaultProps = {
  paddedRows: true,
  columns: [],
  children: null,
};
