import React from "react";
import { connect } from "react-redux";
import { Container, Dimmer, Loader, Message } from "semantic-ui-react";
import _ from "lodash";
import { usersOperations } from "../../redux/users";
import SRHHeader from "../../components/SRHHeader";
import Table from "../../components/Table";
import { UsersPage, PageContent } from "./style.js";
import CreateUserModal from "./components/CreateUserModal";
import UpdateUserModal from "./components/UpdateUserModal";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(usersOperations.fetchUsers());
  }

  render() {
    return (
      <UsersPage>
        <SRHHeader {...this.props} title="Authentication Server" />
        {this.props.usersFetched &&
          !this.props.error && (
            <PageContent>
              <Table
                title="Users"
                buttons={[<CreateUserModal key="CreateUser" {...this.props} />]}
                columns={["ID", "Username", "Roles", "System"]}
              >
                {_.sortBy(this.props.users, "createdDate")
                  .reverse()
                  .map(user => (
                    <UpdateUserModal
                      key={user.id}
                      user={user}
                      trigger={
                        <tr key={`user-${user.username}`}>
                          <td data-label="ID:" style={{ width: "200px" }}>
                            {user.id}
                          </td>
                          <td data-label="Username:">{user.username}</td>
                          <td data-label="Roles:">{(user.roles && user.roles.join(", ")) || "-"}</td>
                          <td data-label="System:">{user.system || "-"}</td>
                        </tr>
                      }
                      {...this.props}
                    />
                  ))}
              </Table>
            </PageContent>
          )}
        {!this.props.usersFetched &&
          !this.props.error && (
            <Dimmer active>
              <Loader size="massive">Loading</Loader>
            </Dimmer>
          )}
        {this.props.error && (
          <Container style={{ paddingTop: "28px" }}>
            <Message
              error
              header="There was an error loading user data"
              content={`${this.props.error.status} (${this.props.error.statusText})`}
            />
          </Container>
        )}
      </UsersPage>
    );
  }
}

export default connect(store => {
  return {
    authenticatedUser: store.user.user,
    users: store.users.data,
    usersFetched: store.users.fetched,
    error: store.users.error,
  };
})(Users);
