import types from "./types";

const fetchUsers = users => {
  return {
    type: types.FETCH_USERS,
    payload: {
      users,
    },
  };
};

const fetchUsersError = err => {
  return {
    type: types.FETCH_USERS_ERROR,
    payload: err,
  };
};

const createUserError = () => {
  return {
    type: types.CREATE_USER_ERROR,
  };
};

const updateUserError = () => {
  return {
    type: types.UPDATE_USER_ERROR,
  };
};

const deleteUserError = () => {
  return {
    type: types.DELETE_USER_ERROR,
  };
};

export default {
  fetchUsers,
  fetchUsersError,
  createUserError,
  updateUserError,
  deleteUserError,
};
