export const USER_SIGN_IN_START = "app/user/USER_SIGN_IN_START";
export const USER_SIGN_IN_ERROR = "app/user/USER_SIGN_IN_ERROR";
export const USER_SIGN_OUT = "app/user/USER_SIGN_OUT";
export const USER_VALIDATE_SESSION = "app/user/USER_VALIDATE_SESSION";
export const SEND_ACCOUNT_SETUP_INSTRUCTIONS = "app/users/SEND_ACCOUNT_SETUP_INSTRUCTIONS";
export const SEND_ACCOUNT_SETUP_INSTRUCTIONS_ERROR = "app/users/SEND_ACCOUNT_SETUP_INSTRUCTIONS_ERROR";
export const SEND_PASSWORD_RESET_PAGE_LOAD = "app/users/SEND_PASSWORD_RESET_PAGE_LOAD";
export const SEND_PASSWORD_RESET = "app/users/SEND_PASSWORD_RESET";
export const SEND_PASSWORD_RESET_ERROR = "app/users/SEND_PASSWORD_RESET_ERROR";
export const VALIDATE_TOKEN = "app/users/VALIDATE_TOKEN";
export const VALIDATE_TOKEN_ERROR = "app/users/VALIDATE_TOKEN_ERROR";
export const PASSWORD_RESET = "app/users/PASSWORD_RESET";
export const PASSWORD_RESET_ERROR = "app/users/PASSWORD_RESET_ERROR";
export const READY_TO_REDIRECT = "app/users/READY_TO_REDIRECT";

export default {
  USER_SIGN_IN_START,
  USER_SIGN_IN_ERROR,
  USER_SIGN_OUT,
  USER_VALIDATE_SESSION,
  SEND_ACCOUNT_SETUP_INSTRUCTIONS,
  SEND_ACCOUNT_SETUP_INSTRUCTIONS_ERROR,
  SEND_PASSWORD_RESET_PAGE_LOAD,
  SEND_PASSWORD_RESET,
  SEND_PASSWORD_RESET_ERROR,
  VALIDATE_TOKEN,
  VALIDATE_TOKEN_ERROR,
  PASSWORD_RESET,
  PASSWORD_RESET_ERROR,
  READY_TO_REDIRECT,
};
