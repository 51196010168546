import axios from "axios";
import actions from "./actions";

const fetchUsers = () => (dispatch, getState) => {
  axios
    .get(`/api/users`)
    .then(res => {
      dispatch(actions.fetchUsers(res.data));
    })
    .catch(err => {
      dispatch(actions.fetchUsersError(err));
    });
};

const createUser = user => (dispatch, getState) => {
  axios
    .post(`/api/users`, user)
    .then(res => {
      dispatch(fetchUsers());
    })
    .catch(err => {
      dispatch(actions.createUserError(err));
    });
};

const updateUser = user => (dispatch, getState) => {
  axios
    .put(`/api/users`, user)
    .then(res => {
      dispatch(fetchUsers());
    })
    .catch(err => {
      dispatch(actions.updateUserError(err));
    });
};

const deleteUser = id => (dispatch, getState) => {
  axios
    .delete(`/api/users/${id}`)
    .then(res => {
      dispatch(fetchUsers());
    })
    .catch(err => {
      dispatch(actions.deleteUserError(err));
    });
};

export default {
  fetchUsers,
  createUser,
  updateUser,
  deleteUser,
};
