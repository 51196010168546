import styled from "styled-components";

export const UsersPage = styled.div`
  &&& {
    display: flex;
    align-items: stretch;
    height: 100%;
    width: 100%;
    position: absolute;
    flex-direction: column;
  }
`;

export const PageContent = styled.div`
  &&& {
    flex-grow: 1;
    position: relative;
  }
`;
