import styled from "styled-components";

export const SendPasswordResetPage = styled.div`
  &&& {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background: #05435e;
  }
`;

export const Modal = styled.div`
  &&& {
    width: 480px;
    height: 580px;
    padding: 113px 80px;
    border-radius: 8px;
    background-color: #fff;
  }
`;

export const ModalMessage = styled.div`
  &&& {
    max-width: 300px;

    h4 {
      font-weight: 300;
      font-size: 20px;
    }

    div {
      font-size: 13px;
    }
  }
`;

export const Logo = styled.div`
  &&& {
    margin: auto;
    max-width: 300px;
    width: 300px;
    height: 170px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 48px;
    font-size: 36px;
    line-height: 170px;
  }
`;
