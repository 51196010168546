import React from "react";
import { Form, Input, Button, Loader, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { ResetPasswordPage, Modal, ModalMessage, Logo } from "./style.js";
import { userOperations } from "../../redux/user";
import qs from "query-string";
import logo from "../../assets/img/logo/fulllogo.svg";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    const location = window.location;
    const token = qs.parse(location?.search)?.token;
    if (!token) {
      console.error("There is no token in the url");
    }
    this.state = {
      token,
      password: "",
      passwordEmpty: false,
      passwordRequirements: false,
      resettingPassword: false,
    };
    this.props.dispatch(userOperations.validateToken(token));
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = () => {
    const { password, token } = this.state;
    if (password) {
      let count = 0;
      if (/.*[a-z].*/.test(password)) {
        count += 1;
      }
      if (/.*[A-Z].*/.test(password)) {
        count += 1;
      }
      if (/.*[0-9].*/.test(password)) {
        count += 1;
      }
      console.log(count);
      if (password.length >= 8 && count >= 3) {
        this.setState({ resettingPassword: true });
        this.props.dispatch(userOperations.resetPassword(password, token));
      } else {
        this.setState({ passwordRequirements: true });
      }
    } else {
      this.setState({ passwordEmpty: true });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.readyToRedirect && nextProps.resetPasswordReferrer) {
      setTimeout(() => {
        document.location.href = nextProps.resetPasswordReferrer;
      }, 3000);
    }
  }

  render() {
    return (
      <ResetPasswordPage>
        <Modal>
          <Logo>
            <img src={logo} alt="Safe and Reliable Care" />
          </Logo>
          {!this.props.tokenValidated &&
            !this.props.tokenValidationError && (
              <div style={{ height: "145px" }}>
                <Loader active style={{ marginTop: "100px" }}>
                  Validating
                </Loader>
              </div>
            )}
          {this.props.tokenValidated &&
            !this.state.resettingPassword && (
              <ModalMessage>
                {this.props.newAccount && <h4>Set Password</h4>}
                {!this.props.newAccount && <h4>Reset Password</h4>}
                <Form onSubmit={this.handleSubmit}>
                  <Form.Field
                    control={Input}
                    type="password"
                    label={this.props.newAccount ? "Password" : "New Password"}
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    required
                  />
                  <Form.Field control={Button} secondary>
                    Set Password
                  </Form.Field>
                </Form>
                {this.state.passwordEmpty && <Message error content="Please enter a password" />}
                {this.state.passwordRequirements && (
                  <Message
                    error
                    content="Password must be at least 8 characters long, and have one each of the following types of characters: lowercase, uppercase, number"
                  />
                )}
              </ModalMessage>
            )}
          {this.state.resettingPassword &&
            !this.props.resetPassword &&
            !this.props.resetPasswordError && (
              <div style={{ height: "145px" }}>
                <Loader active indeterminate style={{ marginTop: "100px" }} />
              </div>
            )}
          {this.props.resetPassword &&
            this.props.resetPasswordReferrer && (
              <div style={{ height: "145px" }}>
                <Loader active style={{ marginTop: "100px" }}>
                  <p>Success!</p>
                  <p>You are now being redirected...</p>
                </Loader>
              </div>
            )}
          {this.props.resetPassword &&
            !this.props.resetPasswordReferrer && (
              <ModalMessage>
                <h4>Success!</h4>
                <div>Your password has been set.</div>
              </ModalMessage>
            )}
          {this.props.resetPasswordError && (
            <ModalMessage style={{ maxWidth: "310px" }}>
              <h4>Server Error</h4>
              <p>
                The server was unable to confirm whether your password was set or not. You can try logging in with your
                new password or try clicking on the link again from your email.
              </p>
              <p>Feel free to email us if you would like assistance:</p>
              <p>
                <strong>surveysupport@safeandreliablecare.com</strong>
              </p>
            </ModalMessage>
          )}
          {this.props.tokenValidationError && (
            <ModalMessage style={{ maxWidth: "310px" }}>
              <h4>Error - Unable to load page</h4>
              <p>Your link may have expired or is invalid.</p>
              <p>Feel free to email us if you would like assistance:</p>
              <p>
                <strong>surveysupport@safeandreliablecare.com</strong>
              </p>
            </ModalMessage>
          )}
        </Modal>
      </ResetPasswordPage>
    );
  }
}

export default connect(store => {
  return {
    newAccount: store.user.newAccount,
    tokenValidated: store.user.tokenValidated,
    tokenValidationError: store.user.tokenValidationError,
    resetPassword: store.user.resetPassword,
    resetPasswordError: store.user.resetPasswordError,
    resetPasswordReferrer: store.user.resetPasswordReferrer,
    readyToRedirect: store.user.readyToRedirect,
  };
})(ResetPassword);
