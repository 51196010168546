import styled from "styled-components";

export const StyledTable = styled.div`
  &&& {
    display: flex;
    align-items: stretch;
    height: 100%;
    width: 100%;
    position: absolute;
    flex-direction: column;
  }
`;

export const TableTitle = styled.div`
  &&& {
    display: flex;
    min-height: 64px;
    overflow: auto;
    margin-top: 28px;
    padding: 14px 28px;
  }
`;

export const Title = styled.div`
  &&& {
    font-size: 24px;
    line-height: 34px;
  }
`;

export const ActionBar = styled.div`
  &&& {
    margin-left: 56px;
    display: inline-flex;
    flex: 1;

    button {
      margin: 0 8px;
    }
  }
`;

export const TableBody = styled.div`
  &&& {
    flex-grow: 1;
    position: relative;
    overflow: auto;
    padding-bottom: 14px;

    table {
      width: 100%;
      cursor: default !important;
      border-collapse: collapse;

      thead {
        tr {
          border-bottom: solid 1px #ddd !important;
        }

        th {
          font-size: 12px;
          font-weight: 400;
          text-transform: uppercase;
          vertical-align: bottom;
          text-align: left;
          white-space: nowrap;
        }

        th.sortable {
          border-radius: 3px 3px 0 0;
          user-select: none;

          &.active,
          &:hover {
            background: #f3f3f3;
          }

          &:not(.active) i {
            color: transparent;
          }

          i {
            font-size: 15px;
            display: inline-block;
            vertical-align: middle;
            padding-bottom: 2px;
            margin-left: 4px;
          }
        }
      }

      tbody {
        tr {
          border-top: solid 1px #ddd !important;

          &:hover {
            background: #f3f3f3;

            .day {
              border-color: #f3f3f3 !important;
            }
          }

          &.selected {
            background: #e6f1ff;

            .day {
              border-color: #e6f1ff !important;
            }

            &:hover {
              background: #dcebff;

              .day {
                border-color: #dcebff !important;
              }
            }
          }
        }
      }

      th,
      td,
      td.ellipsify span {
        padding: 14px 28px;
      }
    }

    table {
      tr {
        th,
        td,
        td.ellipsify span {
          @media (min-width: 759px) {
            &:first-child {
              padding-left: 28px;
            }

            &:last-child {
              padding-right: 28px;
            }
          }
        }
      }
    }

    .skinny-rows {
      td,
      td.ellipsify span {
        padding: 7px 28px;

        i.icon-badge {
          font-size: 22px;
          position: absolute;
          top: 5px;
          font-weight: bold;
        }
      }
    }

    @media (min-width: 760px) {
      table tbody th,
      table tbody td {
        &.wrap {
          min-width: 400px;
          white-space: inherit;
        }
      }
    }

    @media (max-width: 759px) {
      /* Force table to not be like tables anymore */
      table,
      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
      }

      table thead th {
        white-space: inherit;
      }

      table {
        border: none !important;
        min-width: 300px;

        tbody {
          tr {
            border: none;
            border-bottom: 1px solid #ccc;

            &:last-child {
              border: none;
            }

            td {
              width: 100% !important;
            }
          }
        }
      }

      /* Hide table headers (but not display: none;, for accessibility) */
      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      th {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
      }

      th:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
      }

      th {
        width: 100% !important;
        border: none !important;
      }

      th:not(:first-child) {
        text-align: left !important;
      }

      /*
    Label the data
    */
      tbody {
        td:before {
          content: attr(data-label);
          font-weight: bold;
          margin-right: 5px;
        }
      }
    }
  }
`;
