import React from "react";
import { Input, Button, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { SignInPage, Logo } from "./style.js";
import { userOperations } from "../../redux/user";
import logo from "../../assets/img/logo/fulllogo.svg";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.isLoggedIn) {
      const { history } = this.props;
      history.push("/");
    }
    return null;
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { username, password } = this.state;
    this.props.dispatch(userOperations.signIn(username, password));
  }

  render() {
    return (
      <SignInPage>
        <Logo>
          <img src={logo} alt="Safe and Reliable Care" />
        </Logo>
        <form>
          <input name="username" placeholder="Email" onChange={this.handleChange} defaultValue={this.state.email} />
          <input name="password" placeholder="Password" onChange={this.handleChange} type="password" />
          <button onClick={this.handleSubmit}>Sign in</button>
        </form>
        <a
          href={`/send-password-reset?email=${this.state.username}&referrer=${document.URL}`}
          className="forgot-password"
        >
          Forgot your password?
        </a>
        {this.props.lastLoginFailed && (
          <div style={{ color: "#d81111", marginTop: "20px" }}>Unable to authenticate</div>
        )}
      </SignInPage>
    );
  }
}

export default connect(store => {
  return {
    isLoggedIn: store.user.isLoggedIn,
    lastLoginFailed: store.user.lastLoginFailed,
  };
})(SignIn);
