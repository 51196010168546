import React from "react";
import { Form, Input, Button, Loader, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { SendPasswordResetPage, Modal, ModalMessage, Logo } from "./style.js";
import { userOperations } from "../../redux/user";
import qs from "query-string";
import logo from "../../assets/img/logo/fulllogo.svg";

class SendPasswordReset extends React.Component {
  constructor(props) {
    super(props);
    const location = window.location;
    const email = qs.parse(location?.search)?.email;
    const referrer = qs.parse(location?.search)?.referrer;
    this.state = { email, referrer, emailEmpty: false, sendingLink: false };
    this.props.dispatch(userOperations.sendPasswordResetPageLoad());
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = () => {
    const { email, referrer } = this.state;
    if (email) {
      this.setState({ sendingLink: true });
      this.props.dispatch(userOperations.sendPasswordReset(email, referrer));
    } else {
      this.setState({ emailEmpty: true });
    }
  };

  render() {
    return (
      <SendPasswordResetPage>
        <Modal>
          <Logo>
            <img src={logo} alt="Safe and Reliable Care" />
          </Logo>
          {!this.state.sendingLink && (
            <ModalMessage>
              <h4>Reset Password</h4>
              <Form onSubmit={this.handleSubmit}>
                <Form.Field
                  control={Input}
                  label="Email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  required
                />
                <Form.Field control={Button} secondary>
                  Send Reset Link
                </Form.Field>
              </Form>
              {this.state.emailEmpty && <Message error content="Please fill in email" />}
            </ModalMessage>
          )}
          {this.state.sendingLink &&
            !this.props.passwordResetSent &&
            !this.props.passwordResetError && (
              <div style={{ height: "145px" }}>
                <Loader active indeterminate style={{ marginTop: "100px" }} />
              </div>
            )}
          {this.props.passwordResetSent && (
            <ModalMessage>
              <h4>Email sent!</h4>
              <div>Password reset link has been sent to {this.state.email}.</div>
            </ModalMessage>
          )}
          {this.props.passwordResetError && (
            <ModalMessage>
              <h4>Error</h4>
              Try reloading the page or feel free to email us at <strong>surveysupport@safeandreliablecare.com </strong>
              if the issue persists.
            </ModalMessage>
          )}
        </Modal>
      </SendPasswordResetPage>
    );
  }
}

export default connect(store => {
  return {
    passwordResetSent: store.user.passwordResetSent,
    passwordResetError: store.user.passwordResetError,
  };
})(SendPasswordReset);
